import { applyPatch } from "fast-json-patch";
import onElementExists, { type CancelWatcherFunction } from "./onElementExists";
import { type Component, mount, unmount } from "svelte";

type ComponentRegistration = { [key: string]: Component };

const SVELTE_SELECTOR = "[data-svelte-component]"
const MOUNTED = Symbol("mounted")
const UPDATE = Symbol("update")
const PATCH = Symbol("patch")
const beforeRender = "turbo:before-frame-render"
let allComponents : Map<string, Component> = new Map();
let cancelWatcher : CancelWatcherFunction
let didRegisterPermanentUpdate : boolean = false

function unmountFromElement(element : HTMLElement) {
  if(!element[MOUNTED]) {
    // If this is happening, there's probably something broken about our integration
    // with other JavaScript on the page, or the element managed to be removed in the
    // millisecond between being added and queueMicrotask firing?!
    if(!element.dataset.turboPermanent) console.warn("Tried to unmount component that wasn't mounted", element);
    return;
  }
  delete element[PATCH];
  delete element[UPDATE];
  element.removeEventListener(beforeRender, onBeforeFrameRender);
  unmount(element[MOUNTED]);
  delete element[MOUNTED];
}

declare global {
  interface Window {
    Turbo?: any;
  }
}

function onBeforeFrameRender(event) {
  event.detail.render = (currentFrame, newFrame) => {
    currentFrame[UPDATE](childJson(newFrame) || {})
  }
}

function onBeforeRender(event) {
  const newBody = event.detail.newBody
  document.querySelectorAll(`[id][data-turbo-permanent]${SVELTE_SELECTOR}`).forEach(target => {
    if(!target[UPDATE]) return; // Can't think how this would happen
    const next = newBody.querySelector(`#${target.id}`);
    const nextProps = next && childJson(next);
    if(nextProps) target[UPDATE](nextProps);
  })
}

function mountRegistered(target : HTMLElement){
  if(target[MOUNTED]) {
    // Happens with data-turbo-permanent elements re-added to the DOM after a visit:
    // the original ("permanent"!) version of the component should remain.
    // onBeforeRender handles updating the component with possible new version of props.
    return;
  }
  if(target.childElementCount > 1) {
    // I.e., there's something besides the script tag, indicating that the component has
    // been rendered already. Happens during cached renders with turbo:drive, and AFAICT
    // the correct behavior is to do nothing.
    return;
  }
  const data = target.dataset;
  const name = data.svelteComponent;
  const component = allComponents.get(name);
  if(!component) throw new Error(`Tried to mount unregistered Svelte component ${name}`);
  const props = $state(childJson(target) || {})
  if(target.id) {
    const StreamActions = globalThis.Turbo?.StreamActions
    if(StreamActions) {
      if(!StreamActions.svelte_props) StreamActions.svelte_props = svelteSetTurboStreamAction;
      if(!StreamActions.svelte_patch) StreamActions.svelte_patch = sveltePatchTurboStreamAction;
    }
  }
  target[UPDATE] = updateFn(props);
  target[PATCH] = patchFn(props);
  target[MOUNTED] = mount(component, {target, props});
  if(data.updateOnRender) {
    target.addEventListener(beforeRender, onBeforeFrameRender);
  }
  if(data.turboPermanent && !didRegisterPermanentUpdate) {
    didRegisterPermanentUpdate = true
    document.documentElement.addEventListener("turbo:before-render", onBeforeRender);
  }
}

function childJson(element : HTMLElement){
  const child = element.firstElementChild;
  if(!child || child.tagName.toUpperCase() !== "SCRIPT" || child.attributes["type"]?.value !== "application/json") return null;
  return JSON.parse(child.textContent);
}

function updateFn(props) {
  return function(nextProps){
    for (const [key, value] of Object.entries(nextProps)) {
      props[key] = value;
    }
  }
}

function patchFn(props){
  return function(jsonPatchDoc){
    applyPatch(props, jsonPatchDoc, false, true)
  }
}

function svelteSetTurboStreamAction() {
  const nextProps = childJson(this.templateContent);
  this.targetElements.forEach(el=>{
    // Haven't actually run into this problem, but it could happen if you write something very silly
    if(!el[UPDATE]) return console.warn("Tried to set Svelte props on element that doesn't have a Svelte component mounted", el);
    el[UPDATE](nextProps);
  })
}

function sveltePatchTurboStreamAction() {
  const jsonPatchDoc = childJson(this.templateContent);
  this.targetElements.forEach(el=>{
    // Haven't actually run into this problem, but it could happen if you write something very silly
    if(!el[PATCH]) return console.warn("Tried to patch Svelte props on element that doesn't have a Svelte component mounted", el);
    el[PATCH](jsonPatchDoc);
  })
}

export default function registerSvelte(components : ComponentRegistration){
  if(cancelWatcher) cancelWatcher();
  allComponents = new Map([...allComponents, ...Object.entries(components)]);
  cancelWatcher = onElementExists(SVELTE_SELECTOR, (target)=>{
    // queueMicrotask (instead of doing it ASAP) to mount after Turbo Drive has
    // fully restored data-turbo-permanent, which may already have a component
    // mounted that doesn't need to be mounted again.
    queueMicrotask(()=>mountRegistered(target as HTMLElement))
    return unmountFromElement;
  });
}
